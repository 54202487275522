import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import Switch from '@rio-cloud/rio-uikit/Switch';
import isNil from 'lodash/fp/isNil';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { coordsToCoordinates } from '../../common/utils';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { trace } from '../../configuration/setup/trace';
import { fetchIsoline } from '../../features/fetchData/fetchIsoline';
import { GenerateIsolineApiArg, IsolineMode } from '../../store/facade/facadeApi';
import { selectShowVehicleRange, selectTriggerRangeFetch } from '../../store/route/routeSelectors';
import { toggleShowVehicleRange, toggleTriggerRangeFetch } from '../../store/route/routeSlice';
import { selectWaypointOnFocus, selectWaypoints, selectWaypointsOrder } from '../../store/search/searchSelectors';
import { Waypoint, waypointOnFocusChanged } from '../../store/search/searchSlice';
import { selectVehicleProfile } from '../../store/vehicleProfile/vehicleProfileSelectors';
import { clearShapes } from '../../store/widget/actions/renderShapes';
import { showMapNotification } from '../../store/widget/actions/showMapNotification';

const rangeTitleKey = 'intl-msg:smartRoute.isoline.showVehicleRange';
const rangeWarningKey = 'intl-msg:smartRoute.notification.missingRangeOrPosition';

export const ToggleRangeSupport = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const triggerRangeFetch = useAppSelector(selectTriggerRangeFetch);
    const showVehicleRange = useAppSelector(selectShowVehicleRange);
    const waypointOnFocus = useAppSelector(selectWaypointOnFocus);
    const waypointsOrder = useAppSelector(selectWaypointsOrder);
    const waypoints = useAppSelector(selectWaypoints);
    const assetLocked = useAppSelector(selectVehicleProfile).assetLocked;
    const isFirstWaypoint = waypointOnFocus === waypointsOrder[0];

    const assetId = assetLocked?.asset_id;
    const position = assetLocked?.position;
    const totalWeight = assetLocked?.state?.weight_total;
    const remainingRange = assetLocked?.state?.remaining_range;
    const stateOfCharge = assetLocked?.state?.state_of_charge;
    const isToggleRangeDisabled =
        isNil(position?.latitude) || isNil(position?.longitude) || isNil(remainingRange) || isNil(stateOfCharge);

    const fetchWaypointIsoline = async () => {
        let isolineRange: number;

        if (isNil(waypointOnFocus) || isNil(remainingRange) || isNil(stateOfCharge)) {
            return;
        } else if (assetLocked && isFirstWaypoint) {
            isolineRange = remainingRange!;
        } else {
            isolineRange = (100 * remainingRange) / stateOfCharge;
        }
        const isolineCenter = waypoints.find((wp: Waypoint) => wp.id === waypointOnFocus)?.coordinates!;

        try {
            if (!isNil(isolineRange) && !isNil(isolineCenter)) {
                const isolineParams: GenerateIsolineApiArg = {
                    isolineRequest: {
                        asset_id: assetId ?? '',
                        amount: isolineRange * 1000,
                        origin: coordsToCoordinates(isolineCenter),
                        isolineMode: IsolineMode.Distance,
                        weight: totalWeight,
                    },
                };

                await dispatch(fetchIsoline(isolineParams.isolineRequest, true));
            }
        } catch (err) {
            trace.error(err);
            showMapNotification(
                'error',
                intl.formatMessage({ id: 'intl-msg:smartRoute.range.feedback.isoline.error' }),
                intl.formatMessage({ id: 'intl-msg:smartRoute.range.feedback.title' })
            );
        }
    };

    const rangeToggleHandler = () => {
        if (showVehicleRange) {
            dispatch(waypointOnFocusChanged(undefined));
            clearShapes();
        } else {
            dispatch(waypointOnFocusChanged(waypointsOrder[0]));
            dispatch(toggleTriggerRangeFetch(true));
        }
        dispatch(toggleShowVehicleRange());
    };

    useEffect(() => {
        if (showVehicleRange && triggerRangeFetch) {
            fetchWaypointIsoline();
        }
    }, [showVehicleRange, triggerRangeFetch]);

    return (
        <div
            data-testid="toggle-range"
            // eslint-disable-next-line max-len
            className="padding-10 user-select-none rounded border text-medium display-flex flex-row justify-content-between"
        >
            <div>
                <span className="margin-right-10">
                    <FormattedMessage id={rangeTitleKey} />
                </span>
                {isToggleRangeDisabled && (
                    <SimpleTooltip
                        content={
                            <span>
                                <FormattedMessage id={rangeWarningKey} />
                            </span>
                        }
                    >
                        <span className="rioglyph rioglyph-warning-sign" />
                    </SimpleTooltip>
                )}
            </div>

            <Switch
                onChange={rangeToggleHandler}
                checked={showVehicleRange}
                disabled={isToggleRangeDisabled}
                data-testid="srp-switch-tour-range"
            />
        </div>
    );
};
