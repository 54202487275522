import isNil from 'lodash/fp/isNil';
import { GeofenceType } from '../../common/types';
import { coordinatesToCoords } from '../../common/utils';
import { trace } from '../../configuration/setup/trace';
import { GenerateIsolineApiArg, IsolineRequest, IsolineResponse, facadeApi } from '../../store/facade/facadeApi';
import { fetchRangeFailed, fetchRangeTriggered, toggleTriggerRangeFetch } from '../../store/route/routeSlice';
import { changeBoundingBox } from '../../store/widget/actions/changeBoundingBox';
import { ShapeBuilder, renderShapes } from '../../store/widget/actions/renderShapes';
import isUndefined from 'lodash/fp/isUndefined';
import { isEmpty } from 'lodash';

export const fetchIsoline = (isolineRequest: IsolineRequest, focusOnIsoline = false) => {
    return async (dispatch: Function, getState: Function) => {
        try {
            dispatch(fetchRangeTriggered(true));
            const response = await dispatch(facadeApi.endpoints.generateIsoline.initiate({ isolineRequest }));
            const data = response?.data as IsolineResponse;
            if (isUndefined(data) || isUndefined(data.items) || isEmpty(data.items)) {
                throw new Error('Empty isoline response');
            }

            dispatch(fetchRangeTriggered(false));
            dispatch(toggleTriggerRangeFetch(false));
            const isoline = data.items.map((item) => item.points?.map(coordinatesToCoords));

            if (!isNil(isoline)) {
                const isolineShapes = isoline.map((isolinePoints) =>
                    new ShapeBuilder().withType(GeofenceType.Polygon).withPoints(isolinePoints).build()
                );

                renderShapes(...isolineShapes);
                if (focusOnIsoline) {
                    changeBoundingBox(isoline.flat(1));
                }
            }
        } catch (error: any) {
            dispatch(fetchRangeFailed(error?.message));
            trace.error('could not fetch range', error);
            throw error;
        }
    };
};
